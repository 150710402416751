(function () {
  'use strict';

  class LogsRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    logs() {
      return this.$resource(`${this.env.api}/logs`);
    }

  }

  /**
   * @ngdoc service
   * @name components.service:LogsRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('LogsRest', LogsRest);
}());
